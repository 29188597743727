import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
  AiOutlineUser,
  AiOutlineSetting,
  AiOutlineLogout,
} from "react-icons/ai";

const DropdownMenu = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

    const handleLogout = () => {
        // Remove user data from local storage or session storage
        localStorage.removeItem('token');  // If token is stored in localStorage
        localStorage.removeItem('role');   // If user data is stored in localStorage
        navigate('/');
    };

  return (
    <div className="fixed top-4 right-4">
      <button
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="bg-dark-purple text-white p-2 rounded-full focus:outline-none"
      >
        <AiOutlineUser className="text-2xl" />
      </button>
      {dropdownOpen && (
        <div className="absolute right-0 mt-2 w-30 bg-white rounded-md shadow-lg">
          <ul>
            <li className="p-2 flex items-center text-sm text-gray-700 hover:bg-dark-purple hover:text-white">
              {<AiOutlineUser className="m-2" />}
              <a href="/profile" className="block px-1">
                Profile
              </a>
            </li>
            <li className="p-2 flex items-center text-sm text-gray-700 hover:bg-dark-purple hover:text-white">
              {<AiOutlineSetting className="m-2" />}
              <a href="/settings" className="block px-1">
                Settings
              </a>
            </li>
            <li className="p-2 flex items-center text-sm text-gray-700 hover:bg-dark-purple hover:text-white">
              {<AiOutlineLogout className="m-2" />}
              <button onClick={handleLogout} className="block px-1">
                Logout
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
