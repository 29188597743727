import React from 'react';

export default function Dashboardgraphs() {
  return (
        <div className="grid grid-cols-1 gap-4 px-4 mt-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 sm:px-8 mb-5">
        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
            <div className="p-4 bg-dark-purple">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z">
                    </path>
                </svg>
            </div>
            <div className="px-4 text-gray-700">
                <h3 className="text-sm tracking-wider">Total Guest</h3>
                <p className="text-3xl">12</p>
            </div>
        </div>
        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
            <div className="p-4 bg-gray-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="m4 12 8-8 8 8M6 10.5V19a1 1 0 0 0 1 1h3v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h3a1 1 0 0 0 1-1v-8.5">
                    </path>
                </svg>
            </div>
            <div className="px-4 text-gray-700">
                <h3 className="text-sm tracking-wider">Available Suites</h3>
                <p className="text-3xl">3</p>
            </div>
        </div>
        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
            <div className="p-4 bg-blue-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2M12 4v12m0-12 4 4m-4-4L8 8">
                    </path>
                </svg>
            </div>
            <div className="px-4 text-gray-700">
                <h3 className="text-sm tracking-wider">Long Term Guest</h3>
                <p className="text-3xl">12</p>
            </div>
        </div>
        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
            <div className="p-4 bg-green-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M4 15v2a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3v-2m-8 1V4m0 12-4-4m4 4 4-4">
                    </path>
                </svg>
            </div>
            <div className="px-4 text-gray-700">
                <h3 className="text-sm tracking-wider">Short Term Guest</h3>
                <p className="text-3xl">12</p>
            </div>
        </div>
        <div className="flex items-center bg-white border rounded-sm overflow-hidden shadow">
            <div className="p-4 bg-red-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-12 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M13.5 2c-.178 0-.356.013-.492.022l-.074.005a1 1 0 0 0-.934.998V11a1 1 0 0 0 1 1h7.975a1 1 0 0 0 .998-.934l.005-.074A7.04 7.04 0 0 0 22 10.5 8.5 8.5 0 0 0 13.5 2Z">
                    </path>
                    <path d="M11 6.025a1 1 0 0 0-1.065-.998 8.5 8.5 0 1 0 9.038 9.039A1 1 0 0 0 17.975 13H11V6.025Z"/>
                </svg>
            </div>
            <div className="px-4 text-gray-700">
                <h3 className="text-sm tracking-wider">Occupied Capacity</h3>
                <p className="text-3xl">34.12%</p>
            </div>
        </div>
    </div>

  )
}