import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import Link for routing
import AuthService from "./AuthService";
import { FaEnvelope, FaGlobe, FaMobile } from "react-icons/fa";

const backgroundStyle = {
  backgroundSize: "cover",
  backgroundPosition: "center",
};

export default function Signup() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const [formData, setFormData] = useState({
    username:'',
    firstname:'',
    lastname:'',
    dob: '',
    address:'',
    city:'',
    state:'',
    country:'',
    phone:'',
    lang: 'eng',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({  ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await AuthService.register(formData);
      setFormData({
          username:'',
          firstname:'',
          lastname:'',
          dob: '',
          address:'',
          city:'',
          state:'',
          country:'',
          phone:'',
          lang: '',
      });

      setErrors('');

      alert('User registered successfully');
      navigate('/');
      } catch (errors) {
        console.log("Errors: "+errors)
        setErrors(errors.message)
        setTimeout(()=>{
            setErrors('');
        }, 5000);
            // console.log(errors);
            // console.error('Error registering user:', errors);
            // alert('An error occurred while registering user');
      }
  };

  return (
    <div
      style={backgroundStyle}
      className="flex flex-col items-center justify-center min-h-screen py-2"
    >
      <main className="flex flex-col items-center justify-center w-full flex-1 px-4 text-center sm:px-20">
        <div className="bg-white rounded-2xl shadow-2xl flex flex-col w-full max-w-xl sm:flex-row">
          <div className="w-full p-5">
            <div className="text-left font-bold">
              <span className="text-dark-purple">Company</span>
            </div>
            <div className="py-5">
              <h2 className="text-3xl font-bold text-dark-purple mb-1">
                New here?
              </h2>
              <div className="border-2 w-10 border-dark-purple inline-block mb-2"></div>
            </div>
            <p className="mb-2">
              {/* Error codes */}
                {errors && <p className="error-message text-red-500">{errors}</p>}
            </p>
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col items-center mb-3">
                <div className="bg-gray-100 w-full max-w-xs p-2 flex items-center">
                  <input
                    type="text"
                    name="firstname"
                    placeholder="First Name"
                    value={formData.firstname}
                    onChange={handleChange}
                    className="bg-gray-100 outline-none text-sm flex-1"
                  />
                </div>
              </div>
              <div className="flex flex-col items-center mb-3">
                <div className="bg-gray-100 w-full max-w-xs p-2 flex items-center">
                  <input
                    type="text"
                    name="lastname"
                    placeholder="Last Name"
                    value={formData.lastname}
                    onChange={handleChange}
                    className="bg-gray-100 outline-none text-sm flex-1"
                  />
                </div>
              </div>
              <div className="flex flex-col items-center mb-3">
                <div className="bg-gray-100 w-full max-w-xs p-2 flex items-center">
                  <input
                    type="text"
                    name="dob"
                    placeholder="Date of birth YYYY-MM-DD"
                    value={formData.dob}
                    onChange={handleChange}
                    className="bg-gray-100 outline-none text-sm flex-1"
                  />
                </div>                
              </div>
              <div className="flex flex-col items-center mb-3">
                <div className="bg-gray-100 w-full max-w-xs p-2 flex items-center">
                  <input
                    type="text"
                    name="address"
                    placeholder="Address"
                    value={formData.address}
                    onChange={handleChange}
                    className="bg-gray-100 outline-none text-sm flex-1"
                  />
                </div>
              </div>             
              <div className="flex flex-col items-center mb-3">
                <div className="bg-gray-100 w-full max-w-xs p-2 flex items-center">
                  <input
                    type="text"
                    name="city"
                    placeholder="City"
                    value={formData.city}
                    onChange={handleChange}
                    className="bg-gray-100 outline-none text-sm flex-1"
                  />
                </div>
              </div>
              <div className="flex flex-col items-center mb-3">
                <div className="bg-gray-100 w-full max-w-xs p-2 flex items-center">
                  <input
                    type="text"
                    name="state"
                    placeholder="State/Region"
                    value={formData.state}
                    onChange={handleChange}
                    className="bg-gray-100 outline-none text-sm flex-1"
                  />
                </div>
              </div>
              <div className="flex flex-col items-center mb-3">
                <div className="bg-gray-100 w-full max-w-xs p-2 flex items-center">
                  <FaEnvelope className="text-dark-purple m-2" />
                  <input
                    type="email"
                    name="username"
                    placeholder="Email"
                    value={formData.username}
                    onChange={handleChange}
                    className="bg-gray-100 outline-none text-sm flex-1"
                  />
                </div>
              </div>
              <div className="flex flex-col items-center mb-3">
                <div className="bg-gray-100 w-full max-w-xs p-2 flex items-center">
                  <FaMobile className="text-dark-purple m-2" />
                  <input
                    type="text"
                    name="phone"
                    placeholder="Phone number eg 237000000000"
                    value={formData.phone}
                    onChange={handleChange}
                    className="bg-gray-100 outline-none text-sm flex-1"
                  />
                </div>
              </div>
              <div className="flex flex-col items-center mb-3">
                <div className="bg-gray-100 w-full max-w-xs p-2 flex items-center">
                  <FaGlobe className="text-dark-purple m-2" />
                  <select
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    className="bg-gray-100 outline-none text-sm flex-1"
                  >
                    <option value="">Select Country</option>
                    <option value="CM">Cameroon</option>
                    <option value="US">United States of America</option>
                  </select>
                </div>
              </div>
              <button
                type="submit"
                className="border-2 border-dark-purple text-dark-purple rounded-full px-10 py-2 inline-block font-semibold hover:bg-dark-purple hover:text-white mt-3"
              >
                Sign Up
              </button>
            </form>
            <div className="mt-5">
              {/* Use Link for Sign Up button */}
              <Link
                to="/"
                className="text-sm text-dark-purple hover:underline"
              >
                Have an account? Log In
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
