import React, { useEffect, useState } from 'react';
import Sidebar from "../global/Sidebar";
import DropdownMenu from "../global/DropdownMenu";
import NotificationMenu from "../global/NotificationMenu";
import AuthService from "../auth/AuthService";

const Buildings = () => {
  document.title = "Buildings";

  const [buildings, setBuildings] = useState([]);
  const [page, setPage] = useState(0); 
  const [size, setSize] = useState(10); 
  const [totalPages, setTotalPages] = useState(1); 
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBuildings();
  }, [page, size]); 

  const fetchBuildings = async () => {
    try {
      const token = localStorage.getItem('token'); 
      const orgId = localStorage.getItem('org'); 
      const response = await AuthService.getOrgBuildings(token, orgId, page, size);
      setBuildings(response.content); 
      setTotalPages(response.totalPages); 
    } catch (error) {
      console.error('Error fetching buildings information:', error);
      setError(error.message); 
    }
  };

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1); 
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1); 
    }
  };

  return (
    <div className="flex h-screen bg-gray-100 overflow-hidden">
      <Sidebar />
      <main className="flex-1 flex  flex-col overflow-y-auto">
        <div className="flex justify-between items-center bg-top-bar p-3 md:p-9 shadow-md">
          <div className="flex items-center space-x-4 z-20">
            <NotificationMenu />
            <DropdownMenu />
          </div>
        </div>
        <div className="p-3 md:p-7 bg-gray-300 flex-1 z-10">
          <div className="container mx-auto p-4">
            {/* Table Header */}
            <div className='mb-5 text-right'>
            <a href="/addbuilding" className="bg-dark-purple text-white py-2 px-4 rounded font-bold hover:bg-white hover:text-dark-purple">Add Building</a>
            </div>
            <table className="min-w-full bg-white rounded-md">
              <thead className="text-white font-bold sentencecase bg-dark-purple rounded-md">
                <tr>
                  <th className="py-2 px-4 border-b">Building</th>
                  <th className="py-2 px-4 border-b">Name of Building</th>
                  <th className="py-2 px-4 border-b">City</th>
                  <th className="py-2 px-4 border-b">State</th>
                  <th className="py-2 px-4 border-b">Country</th>
                  <th className="py-2 px-4 border-b">Action</th>
                </tr>
              </thead>
              <tbody>
                {/* Table Rows */}
                {buildings.map((item) => (
                  <tr key={item.buildingId} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b">
                      <img  className="w-32 h-24 object-cover" src={item.imageUrls && item.imageUrls.length > 0 ? item.imageUrls[0] : '/default-image.jpg'}
      alt={item.buildingName} />
                    </td>
                    <td className="py-2 px-4 border-b text-lg text-center font-semibold text-gray-500">
                      {item.buildingName}
                    </td>
                    <td className="py-2 px-4 border-b font-bold text-gray-500 text-center">{item.city}</td>
                    <td className="py-2 px-4 border-b font-bold text-gray-500 text-center">{item.state}</td>
                    <td className="py-2 px-4 border-b font-bold text-gray-500 text-center">{item.country}</td>
                    <td className="py-2 px-4 border-b text-center">
                      <a
                        href={`/building/${item.buildingId}`}
                        className="bg-dark-purple text-white py-2 px-4 rounded hover:bg-gray-400"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Pagination Controls */}
            <div className="flex justify-between mt-4">
              <button
                onClick={handlePreviousPage}
                disabled={page === 0}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded disabled:opacity-50"
              >
                Previous
              </button>
              <span> Page {page + 1} of {totalPages} </span>
              <button
                onClick={handleNextPage}
                disabled={page === totalPages - 1}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Buildings;