import React, { useState } from 'react';
import Sidebar from "../global/Sidebar";
import DropdownMenu from '../global/DropdownMenu'; 
import NotificationMenu from '../global/NotificationMenu';
import { data } from "./admindata";

const rowsPerPage = 20;

export default function Admin() {
  document.title = "Admin"
  const [expandedRow, setExpandedRow] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("details");

  const handleRowClick = (id) => {
    setExpandedRow(id);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const handleBackToTable = () => {
    setExpandedRow(null);
  };

  const totalPages = Math.ceil(data.length / rowsPerPage);
  const paginatedData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const getPageNumbers = () => {
    const maxPageNumbers = 5;
    const halfPageNumbers = Math.floor(maxPageNumbers / 2);
    let startPage = Math.max(1, currentPage - halfPageNumbers);
    let endPage = Math.min(totalPages, currentPage + halfPageNumbers);

    if (currentPage - halfPageNumbers <= 0) {
      endPage = Math.min(totalPages, maxPageNumbers);
    }

    if (currentPage + halfPageNumbers > totalPages) {
      startPage = Math.max(1, totalPages - maxPageNumbers + 1);
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers;
  };
  

  return (
    <div className="flex h-screen bg-gray-100 overflow-hidden">
      <Sidebar />
      <main className="flex-1 flex  flex-col overflow-y-auto">
        <div className="flex justify-between items-center bg-top-bar p-3 md:p-9 shadow-md">
          <div className="flex items-center space-x-4 z-20">
            <NotificationMenu />
            <DropdownMenu />
          </div>
        </div>
              <div className="p-7 bg-gray-300 flex-1 z-10">
                    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                    <table className="min-w-full text-sm md:text-base text-left rtl:text-right">
                    <thead className="text-xs md:text-sm text-white font-bold uppercase bg-dark-purple">
                        <tr>
                        <th scope="col" className="px-2 md:px-4 py-1 md:py-2">
                            First Name
                        </th>
                        <th scope="col" className="px-2 md:px-4 py-1 md:py-2">
                            Last Name
                        </th>
                        <th scope="col" className="px-2 md:px-4 py-1 md:py-2">
                            City
                        </th>
                        <th scope="col" className="px-2 md:px-4 py-1 md:py-2">
                            Region
                        </th>
                        <th scope="col" className="px-2 md:px-4 py-1 md:py-2">
                            Country
                        </th>
                        <th scope="col" className="px-2 md:px-4 py-1 md:py-2">
                            Phone
                        </th>
                        <th scope="col" className="px-2 md:px-4 py-1 md:py-2">
                            Suite
                        </th>
                        <th scope="col" className="px-2 md:px-4 py-1 md:py-2">
                            Role
                        </th>
                        </tr>
                    </thead>
                    <tbody className="bg-gray-50">
                        {paginatedData.map((item) => (
                        <React.Fragment key={item.id}>
                            <tr
                            onClick={() => handleRowClick(item.id)}
                            className="cursor-pointer hover:bg-gray-200 "
                            >
                            <td className="px-2 md:px-4 py-1">{item.first_name}</td>
                            <td className="px-2 md:px-4 py-1">{item.last_name}</td>
                            <td className="px-2 md:px-4 py-1">{item.city}</td>
                            <td className="px-2 md:px-4 py-1">{item.region}</td>
                            <td className="px-2 md:px-4 py-1">{item.country}</td>
                            <td className="px-2 md:px-4 py-1">23789878767</td>
                            <td className="px-2 md:px-4 py-1">#001</td>
                            <td className="px-2 md:px-4 py-1">Short Term</td>
                            </tr>
                            {expandedRow === item.id && (
                            <tr className="bg-gray-100">
                                <td colSpan="9" className="py-2 px-2 md:px-4">
                                <div className="p-3 md:p-4 border border-gray-200 rounded">
                                    {item.content}
                                </div>
                                </td>
                            </tr>
                            )}
                        </React.Fragment>
                        ))}
                    </tbody>
                    </table>
                    <div className="flex justify-between mt-3 md:mt-4 flex-wrap mb-2">
                    <button
                        onClick={() => handlePageClick(currentPage - 1)}
                        disabled={currentPage === 1}
                        className={`flex items-center justify-center px-2 md:px-3 h-8 leading-tight text-dark-purple bg-white border border-gray-300 rounded-lg 
                                ${
                                currentPage === 1
                                    ? "opacity-50 cursor-not-allowed"
                                    : "hover:bg-dark-purple hover:text-white"
                                }`}
                    >
                        Previous
                    </button>
                    <div className="flex space-x-1 md:space-x-2 overflow-auto">
                        {getPageNumbers().map((page) => (
                        <button
                            key={page}
                            onClick={() => handlePageClick(page)}
                            className={`px-2 md:px-3 py-1 md:py-2 rounded-md shadow-md ${
                            currentPage === page
                                ? "bg-dark-purple text-white"
                                : "bg-white text-dark-purple hover:bg-gray-300"
                            }`}
                        >
                            {page}
                        </button>
                        ))}
                    </div>
                    <button
                        onClick={() => handlePageClick(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className={`flex items-center justify-center px-2 md:px-3 h-8 leading-tight text-dark-purple bg-white border border-gray-300 rounded-lg 
                                ${
                                currentPage === totalPages
                                    ? "opacity-50 cursor-not-allowed"
                                    : "hover:bg-dark-purple hover:text-white"
                                }`}
                    >
                        Next
                    </button>
                    </div>
                </div>
                  
             </div>
           </main>
    </div>
  )
}
