import React, { useEffect, useState } from 'react';
import Sidebar from "../global/Sidebar"; 
import NotificationMenu from "../global/NotificationMenu"; 
import DropdownMenu from "../global/DropdownMenu"; 
import AuthService from "../auth/AuthService";
import { useParams } from 'react-router-dom';

const buildingData = {
  id: "1",
  name: "Empire State Building",
  address: "350 5th Ave",
  city: "New York",
  state: "NY",
  country: "USA",
  phoneNumber: "(212) 736-3100",
  image: "https://example.com/empire-state-building.jpg", 
};

export default function Building() { // Changed "building" to "Building"
  document.title = "Building";
  const { buildingid } = useParams(); 
  const [building, setBuilding] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchBuildings();
  }, [page, size]); 

  const fetchBuildings = async () => {
    try {
      const token = localStorage.getItem('token'); 
      const response = await AuthService.getBuilding(token, buildingid, page, size);
      setBuilding(response.building); 
      setRooms(response.rooms.content); 
      setTotalPages(response.rooms.totalPages); 
    } catch (error) {
      console.error('Error fetching buildings information:', error);
      setError(error.message); 
    }
  };

  const handleNextPage = () => {
    if (page < totalPages - 1) {
      setPage(page + 1); 
    }
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage(page - 1); 
    }
  };

  return (
    <div className="flex h-screen bg-gray-100 overflow-hidden">
      <Sidebar />
      <main className="flex-1 flex flex-col overflow-y-auto">
        <div className="flex justify-between items-center bg-top-bar p-3 md:p-9 shadow-md">
          <div className="flex items-center space-x-4 z-20">
            <NotificationMenu />
            <DropdownMenu />
          </div>
        </div>
        <div className="p-3 md:p-7 bg-gray-300 flex-1 z-10">
          {/* Building Header */}
          {building && (
              <div className="bg-white rounded-md shadow-md p-4 mb-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
              {/* Image Rows */}
              {building.imageUrls.map((url, index) => (
                <div key={index} className="flex items-center justify-center">
                  <img
                    src={url}
                    alt={`Image ${index + 1}`}
                    className="w-full h-50 md:h-50 object-cover rounded-md"
                  />
                </div>
              ))}
            
              {/* Address and Building Info */}
              <div className="col-span-1 sm:col-span-2 md:col-span-4 flex flex-col justify-center">
                <h2 className="text-xl md:text-2xl font-bold mb-2">{building.buildingName}</h2>
                <p className="text-gray-600 mb-1">{building.address}</p>
                <p className="text-gray-600 mb-1">
                  {building.city}, {building.state}, {building.country}
                </p>
                <p className="text-gray-600 mb-1">Phone: {building.phone}</p>
              </div>
            </div>
                       
           )}

            <div className='mb-5 text-right'>
              <a href="/building/addroom" className="bg-dark-purple text-white py-2 px-4 font-bold rounded hover:bg-white hover:text-dark-purple">Add Room</a>
            </div>

          {/* Rooms Table */}
          <div className="bg-white rounded-md shadow-md p-4">
            <table className="min-w-full bg-white rounded-md">
            <thead className="text-white font-bold sentencecase bg-dark-purple rounded-md">
              <tr>
                <th className="py-2 px-4 border-b">Room Image</th>
                <th className="py-2 px-4 border-b">Room Type</th>
                <th className="py-2 px-4 border-b">Room #</th>
                <th className="py-2 px-4 border-b">Beds</th>
                <th className="py-2 px-4 border-b">Baths</th>
                <th className="py-2 px-4 border-b">Occupancy</th>
                <th className="py-2 px-4 border-b">Status</th>
                <th className="py-2 px-4 border-b">Action</th>
              </tr>
            </thead>
              <tbody>
                {/* Table Rows */}
                {rooms.map((room) => (
                  <tr key={room.id} className="hover:bg-gray-100">
                    <td className="py-2 px-4 border-b text-center">
                      <img className="w-32 h-24 object-cover" src={room.image} alt={room.title} />
                    </td>
                    <td className="py-2 px-4 border-b font-bold text-gray-500 text-center">{room.roomType}</td>
                    <td className="py-2 px-4 border-b font-bold text-gray-500 text-center">{room.unitnumber}</td>
                    <td className="py-2 px-4 border-b font-bold text-gray-500 text-center">{room.bedrooms}</td>
                    <td className="py-2 px-4 border-b font-bold text-gray-500 text-center">{room.bathrooms}</td>
                    <td className="py-2 px-4 border-b font-bold text-gray-500 text-center">{room.capacity}</td>
                    <td className="py-2 px-4 border-b font-bold text-gray-500 text-center">{room.status}</td>
                    <td className="py-2 px-4 border-b font-bold text-gray-500 text-center">
                      <a
                        href={`/building/room/${room.roomId}`}
                        className="bg-dark-purple text-white py-2 px-4 rounded hover:bg-gray-400"
                      >
                        View
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="flex justify-between mt-4">
              <button
                onClick={handlePreviousPage}
                disabled={page === 0}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded disabled:opacity-50"
              >
                Previous
              </button>
              <button
                onClick={handleNextPage}
                disabled={page === totalPages - 1}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
