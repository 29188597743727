import React, { useState } from 'react';
import Sidebar from "../global/Sidebar";
import DropdownMenu from '../global/DropdownMenu';
import NotificationMenu from '../global/NotificationMenu';

const initialData = [
  { id: 1, firstName: 'John', lastName: 'Doe', gender: 'Male', age: 19, city: 'New York', region: 'New York', phone: '(123)456-7890' },
  { id: 2, firstName: 'Jane', lastName: 'Doe', gender: 'Female', age: 17, city: 'Los Angeles', region: 'California', phone: '(123)456-7890' },
  { id: 3, firstName: 'John', lastName: 'Smith', gender: 'Male', age: 29, city: 'Chicago', region: 'Illinois', phone: '(123)456-7890' },
  { id: 4, firstName: 'Alice', lastName: 'Johnson', gender: 'Female', age: 25, city: 'Houston', region: 'Texas', phone: '(123)456-7890' },
  { id: 5, firstName: 'Bob', lastName: 'Brown', gender: 'Male', age: 32, city: 'Phoenix', region: 'Arizona', phone: '(123)456-7890' },
  { id: 6, firstName: 'Emily', lastName: 'Davis', gender: 'Female', age: 22, city: 'Philadelphia', region: 'Pennsylvania', phone: '(123)456-7890' },
  { id: 7, firstName: 'Michael', lastName: 'Wilson', gender: 'Male', age: 27, city: 'San Antonio', region: 'Texas', phone: '(123)456-7890' },
  { id: 8, firstName: 'Sophia', lastName: 'Martinez', gender: 'Female', age: 30, city: 'San Diego', region: 'California', phone: '(123)456-7890' },
  { id: 9, firstName: 'David', lastName: 'Garcia', gender: 'Male', age: 35, city: 'Dallas', region: 'Texas', phone: '(123)456-7890' },
  { id: 10, firstName: 'Olivia', lastName: 'Lopez', gender: 'Female', age: 28, city: 'San Jose', region: 'California', phone: '(123)456-7890' },
  { id: 11, firstName: 'James', lastName: 'Hernandez', gender: 'Male', age: 24, city: 'Austin', region: 'Texas', phone: '(123)456-7890' },
  { id: 12, firstName: 'Emma', lastName: 'Martinez', gender: 'Female', age: 31, city: 'Jacksonville', region: 'Florida', phone: '(123)456-7890' },
  { id: 13, firstName: 'William', lastName: 'Young', gender: 'Male', age: 26, city: 'San Francisco', region: 'California', phone: '(123)456-7890' },
  { id: 14, firstName: 'Ava', lastName: 'Gonzalez', gender: 'Female', age: 23, city: 'Columbus', region: 'Ohio', phone: '(123)456-7890' },
  { id: 15, firstName: 'Alexander', lastName: 'Rodriguez', gender: 'Male', age: 20, city: 'Indianapolis', region: 'Indiana', phone: '(123)456-7890' },
  { id: 16, firstName: 'Mia', lastName: 'Perez', gender: 'Female', age: 18, city: 'Seattle', region: 'Washington', phone: '(123)456-7890' },
  { id: 17, firstName: 'Benjamin', lastName: 'Sanchez', gender: 'Male', age: 21, city: 'Denver', region: 'Colorado', phone: '(123)456-7890' },
  { id: 18, firstName: 'Isabella', lastName: 'Torres', gender: 'Female', age: 33, city: 'Washington', region: 'District of Columbia', phone: '(123)456-7890' },
  { id: 19, firstName: 'Elijah', lastName: 'Ramirez', gender: 'Male', age: 34, city: 'Boston', region: 'Massachusetts', phone: '(123)456-7890' },
  { id: 20, firstName: 'Charlotte', lastName: 'Flores', gender: 'Female', age: 36, city: 'Nashville', region: 'Tennessee', phone: '(123)456-7890' },
  { id: 21, firstName: 'Liam', lastName: 'Anderson', gender: 'Male', age: 25, city: 'Portland', region: 'Oregon', phone: '(123)456-7890' },
  { id: 22, firstName: 'Emma', lastName: 'Garcia', gender: 'Female', age: 27, city: 'Oklahoma City', region: 'Oklahoma', phone: '(123)456-7890' },
  { id: 23, firstName: 'Noah', lastName: 'Martinez', gender: 'Male', age: 29, city: 'Las Vegas', region: 'Nevada', phone: '(123)456-7890' },
  { id: 24, firstName: 'Olivia', lastName: 'Jones', gender: 'Female', age: 31, city: 'Louisville', region: 'Kentucky', phone: '(123)456-7890' },
  { id: 25, firstName: 'William', lastName: 'Davis', gender: 'Male', age: 22, city: 'Baltimore', region: 'Maryland', phone: '(123)456-7890' },
  { id: 26, firstName: 'Ava', lastName: 'Brown', gender: 'Female', age: 24, city: 'Milwaukee', region: 'Wisconsin', phone: '(123)456-7890' },
  { id: 27, firstName: 'James', lastName: 'Gonzalez', gender: 'Male', age: 33, city: 'Albuquerque', region: 'New Mexico', phone: '(123)456-7890' },
  { id: 28, firstName: 'Sophia', lastName: 'Johnson', gender: 'Female', age: 26, city: 'Tucson', region: 'Arizona', phone: '(123)456-7890' },
  { id: 29, firstName: 'Benjamin', lastName: 'Rodriguez', gender: 'Male', age: 30, city: 'Fresno', region: 'California', phone: '(123)456-7890' },
  { id: 30, firstName: 'Charlotte', lastName: 'Young', gender: 'Female', age: 28, city: 'Sacramento', region: 'California', phone: '(123)456-7890' },
  { id: 31, firstName: 'Elijah', lastName: 'Sanchez', gender: 'Male', age: 19, city: 'Long Beach', region: 'California', phone: '(123)456-7890' },
  { id: 32, firstName: 'Amelia', lastName: 'Torres', gender: 'Female', age: 21, city: 'Kansas City', region: 'Missouri', phone: '(123)456-7890' },
  { id: 33, firstName: 'Lucas', lastName: 'Ramirez', gender: 'Male', age: 32, city: 'Mesa', region: 'Arizona', phone: '(123)456-7890' },
  { id: 34, firstName: 'Mia', lastName: 'Flores', gender: 'Female', age: 23, city: 'Atlanta', region: 'Georgia', phone: '(123)456-7890' },
  { id: 35, firstName: 'Henry', lastName: 'Anderson', gender: 'Male', age: 36, city: 'Miami', region: 'Florida', phone: '(123)456-7890' },
  { id: 36, firstName: 'Emily', lastName: 'Garcia', gender: 'Female', age: 25, city: 'Oakland', region: 'California', phone: '(123)456-7890' },
  { id: 37, firstName: 'Daniel', lastName: 'Martinez', gender: 'Male', age: 27, city: 'Minneapolis', region: 'Minnesota', phone: '(123)456-7890' },
  { id: 38, firstName: 'Madison', lastName: 'Jones', gender: 'Female', age: 29, city: 'Tampa', region: 'Florida', phone: '(123)456-7890' },
  { id: 39, firstName: 'Jackson', lastName: 'Davis', gender: 'Male', age: 34, city: 'Cleveland', region: 'Ohio', phone: '(123)456-7890' },
  { id: 40, firstName: 'Avery', lastName: 'White', gender: 'Female', age: 30, city: 'Honolulu', region: 'Hawaii', phone: '(123)456-7890' },
  { id: 41, firstName: 'Ethan', lastName: 'Thompson', gender: 'Male', age: 25, city: 'Anaheim', region: 'California', phone: '(123)456-7890' },
  { id: 42, firstName: 'Sofia', lastName: 'Hernandez', gender: 'Female', age: 27, city: 'Arlington', region: 'Texas', phone: '(123)456-7890' },
  { id: 43, firstName: 'Logan', lastName: 'Young', gender: 'Male', age: 29, city: 'Pittsburgh', region: 'Pennsylvania', phone: '(123)456-7890' },
  { id: 44, firstName: 'Chloe', lastName: 'Lopez', gender: 'Female', age: 31, city: 'Cincinnati', region: 'Ohio', phone: '(123)456-7890' },
  { id: 45, firstName: 'Lucas', lastName: 'Gonzalez', gender: 'Male', age: 22, city: 'Orlando', region: 'Florida', phone: '(123)456-7890' },
  { id: 46, firstName: 'Lily', lastName: 'Martinez', gender: 'Female', age: 24, city: 'St. Louis', region: 'Missouri', phone: '(123)456-7890' },
  { id: 47, firstName: 'Jackson', lastName: 'King', gender: 'Male', age: 33, city: 'Riverside', region: 'California', phone: '(123)456-7890' },
  { id: 48, firstName: 'Sophia', lastName: 'Harris', gender: 'Female', age: 26, city: 'Lexington', region: 'Kentucky', phone: '(123)456-7890' },
  { id: 49, firstName: 'Daniel', lastName: 'Clark', gender: 'Male', age: 30, city: 'Stockton', region: 'California', phone: '(123)456-7890' },
  { id: 50, firstName: 'Mia', lastName: 'Wright', gender: 'Female', age: 28, city: 'Anchorage', region: 'Alaska', phone: '(123)456-7890' },
  { id: 51, firstName: 'Matthew', lastName: 'Scott', gender: 'Male', age: 19, city: 'Columbus', region: 'Ohio', phone: '(123)456-7890' },
  { id: 52, firstName: 'Olivia', lastName: 'Green', gender: 'Female', age: 21, city: 'Newark', region: 'New Jersey', phone: '(123)456-7890' },
  { id: 53, firstName: 'Lucas', lastName: 'Adams', gender: 'Male', age: 32, city: 'Jersey City', region: 'New Jersey', phone: '(123)456-7890' },
  { id: 54, firstName: 'Ava', lastName: 'Baker', gender: 'Female', age: 23, city: 'Jersey City', region: 'New Jersey', phone: '(123)456-7890' },
  { id: 55, firstName: 'Jacob', lastName: 'Gonzalez', gender: 'Male', age: 36, city: 'Buffalo', region: 'New York', phone: '(123)456-7890' },
  { id: 56, firstName: 'Sophia', lastName: 'Perez', gender: 'Female', age: 25, city: 'Durham', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 57, firstName: 'William', lastName: 'Torres', gender: 'Male', age: 27, city: 'Charlotte', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 58, firstName: 'Isabella', lastName: 'Gray', gender: 'Female', age: 29, city: 'Raleigh', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 59, firstName: 'Alexander', lastName: 'Collins', gender: 'Male', age: 34, city: 'Greensboro', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 60, firstName: 'Sophia', lastName: 'Edwards', gender: 'Female', age: 30, city: 'Winston-Salem', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 61, firstName: 'Ethan', lastName: 'Mitchell', gender: 'Male', age: 25, city: 'Fayetteville', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 62, firstName: 'Amelia', lastName: 'Young', gender: 'Female', age: 27, city: 'Asheville', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 63, firstName: 'James', lastName: 'Martinez', gender: 'Male', age: 29, city: 'Chapel Hill', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 64, firstName: 'Ella', lastName: 'Jackson', gender: 'Female', age: 31, city: 'Boone', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 65, firstName: 'Logan', lastName: 'Rodriguez', gender: 'Male', age: 22, city: 'Cary', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 66, firstName: 'Grace', lastName: 'Hernandez', gender: 'Female', age: 24, city: 'Jacksonville', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 67, firstName: 'Jack', lastName: 'Lopez', gender: 'Male', age: 33, city: 'Wilmington', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 68, firstName: 'Avery', lastName: 'Garcia', gender: 'Female', age: 26, city: 'Hickory', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 69, firstName: 'Leo', lastName: 'Davis', gender: 'Male', age: 30, city: 'Concord', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 70, firstName: 'Stella', lastName: 'Martinez', gender: 'Female', age: 28, city: 'High Point', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 71, firstName: 'Wyatt', lastName: 'Clark', gender: 'Male', age: 19, city: 'Salisbury', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 72, firstName: 'Nova', lastName: 'Smith', gender: 'Female', age: 21, city: 'Gastonia', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 73, firstName: 'Mateo', lastName: 'Johnson', gender: 'Male', age: 32, city: 'Mooresville', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 74, firstName: 'Luna', lastName: 'Williams', gender: 'Female', age: 23, city: 'Kannapolis', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 75, firstName: 'Hudson', lastName: 'Brown', gender: 'Male', age: 36, city: 'Matthews', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 76, firstName: 'Zoe', lastName: 'Jones', gender: 'Female', age: 25, city: 'Statesville', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 77, firstName: 'Axel', lastName: 'Gonzalez', gender: 'Male', age: 27, city: 'Durham', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 78, firstName: 'Mila', lastName: 'Rodriguez', gender: 'Female', age: 29, city: 'Greenville', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 79, firstName: 'Jameson', lastName: 'Martinez', gender: 'Male', age: 34, city: 'Wake Forest', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 80, firstName: 'Adeline', lastName: 'Ramirez', gender: 'Female', age: 30, city: 'Rocky Mount', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 81, firstName: 'Jaxon', lastName: 'Sanchez', gender: 'Male', age: 25, city: 'Wilson', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 82, firstName: 'Aria', lastName: 'Flores', gender: 'Female', age: 27, city: 'Asheboro', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 83, firstName: 'Eli', lastName: 'Garcia', gender: 'Male', age: 29, city: 'Chapel Hill', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 84, firstName: 'Aurora', lastName: 'Taylor', gender: 'Female', age: 31, city: 'Boone', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 85, firstName: 'Roman', lastName: 'Lopez', gender: 'Male', age: 22, city: 'Cary', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 86, firstName: 'Layla', lastName: 'Martinez', gender: 'Female', age: 24, city: 'Jacksonville', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 87, firstName: 'Kingston', lastName: 'Brown', gender: 'Male', age: 33, city: 'Wilmington', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 88, firstName: 'Bella', lastName: 'Garcia', gender: 'Female', age: 26, city: 'Hickory', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 89, firstName: 'Asher', lastName: 'Davis', gender: 'Male', age: 30, city: 'Concord', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 90, firstName: 'Nova', lastName: 'Martinez', gender: 'Female', age: 28, city: 'High Point', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 91, firstName: 'Mateo', lastName: 'Clark', gender: 'Male', age: 19, city: 'Salisbury', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 92, firstName: 'Luna', lastName: 'Smith', gender: 'Female', age: 21, city: 'Gastonia', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 93, firstName: 'Hudson', lastName: 'Johnson', gender: 'Male', age: 32, city: 'Mooresville', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 94, firstName: 'Zoe', lastName: 'Williams', gender: 'Female', age: 23, city: 'Kannapolis', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 95, firstName: 'Axel', lastName: 'Brown', gender: 'Male', age: 36, city: 'Matthews', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 96, firstName: 'Mila', lastName: 'Jones', gender: 'Female', age: 25, city: 'Statesville', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 97, firstName: 'Jameson', lastName: 'Gonzalez', gender: 'Male', age: 27, city: 'Durham', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 98, firstName: 'Adeline', lastName: 'Rodriguez', gender: 'Female', age: 29, city: 'Greenville', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 99, firstName: 'Jaxon', lastName: 'Martinez', gender: 'Male', age: 34, city: 'Wake Forest', region: 'North Carolina', phone: '(123)456-7890' },
  { id: 100, firstName: 'Aria', lastName: 'Ramirez', gender: 'Female', age: 30, city: 'Rocky Mount', region: 'North Carolina', phone: '(123)456-7890' }
];


const itemsPerPage = 20;

export default function Guests() {
  document.title = "Guests";

  const [data, setData] = useState(initialData);
  const [currentPage, setCurrentPage] = useState(1);
  const [viewingUser, setViewingUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedUser, setEditedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedRow, setExpandedRow] = useState(null);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const currentItems = data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleDeleteUser = (id) => {
    setData(data.filter(user => user.id !== id));
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const filteredData = initialData.filter(user =>
      user.firstName.toLowerCase().includes(event.target.value.toLowerCase()) ||
      user.lastName.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setData(filteredData);
    setCurrentPage(1); // Reset to first page when searching
  };

  const handleEditUser = (user) => {
    setEditedUser({ ...user });
    setEditMode(true);
  };

  const handleSaveEdit = () => {
    // Update the data array with edited user
    setData(data.map(user => (user.id === editedUser.id ? editedUser : user)));
    setViewingUser({ ...editedUser }); // Update viewingUser to reflect changes
    setEditMode(false);
  };

  const handleCancelEdit = () => {
    setEditedUser(null);
    setEditMode(false);
  };

  const toggleRow = (id) => {
    setExpandedRow(id === expandedRow ? null : id);
  };

  return (
    <div className="flex h-screen bg-gray-100 overflow-hidden">
      <Sidebar />
      <main className="flex-1 flex flex-col overflow-y-auto">
        <div className="flex justify-between items-center bg-top-bar p-3 md:p-9 shadow-md">
          <div className="flex items-center space-x-4 z-20">
            <NotificationMenu />
            <DropdownMenu />
          </div>
        </div>
        <div className="p-3 md:p-7 bg-gray-300 flex-1 z-10">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <div className="relative w-64 mb-2 float-right">
              <input
                type="text"
                className="w-full px-4 py-2 text-sm bg-white border border-gray-300 rounded-md focus:outline-none focus:border-purple-500"
                placeholder="Search by name..."
                value={searchTerm}
                onChange={handleSearch}
              />
            </div>
            <table className="w-full text-sm text-left rtl:text-right ">
              <thead className="text-xs text-white font-bold uppercase bg-dark-purple">
                <tr>
                  <th scope="col" className="px-6 py-3">First Name</th>
                  <th scope="col" className="px-6 py-3">Last Name</th>
                  <th scope="col" className="px-6 py-3">Gender</th>
                  <th scope="col" className="px-6 py-3">Age</th>
                  <th scope="col" className="px-6 py-3">City</th>
                  <th scope="col" className="px-6 py-3">Region</th>
                  <th scope="col" className="px-6 py-3">Phone</th>
                </tr>
              </thead>
              <tbody className="bg-gray-50 text-gray-500 font-bold">
                {currentItems.map((guest, index) => (
                  <React.Fragment key={guest.id}>
                    <tr onClick={() => toggleRow(guest.id)} className="cursor-pointer hover:bg-gray-200">
                      <td className="px-6 py-1">{guest.firstName}</td>
                      <td className="px-6 py-1">{guest.lastName}</td>
                      <td className="px-6 py-1">{guest.gender}</td>
                      <td className="px-6 py-1">{guest.age}</td>
                      <td className="px-6 py-1">{guest.city}</td>
                      <td className="px-6 py-1">{guest.region}</td>
                      <td className="px-6 py-1">{guest.phone}</td>
                    </tr>
                    {expandedRow === guest.id && (
                      <tr>
                        <td colSpan="7" className="p-4">
                          <div className="bg-white p-4 rounded shadow-md">
                            <p><strong>First Name:</strong> {guest.firstName}</p>
                            <p><strong>Last Name:</strong> {guest.lastName}</p>
                            <p><strong>Gender:</strong> {guest.gender}</p>
                            <p><strong>Age:</strong> {guest.age}</p>
                            <p><strong>City:</strong> {guest.city}</p>
                            <p><strong>Region:</strong> {guest.region}</p>
                            <p><strong>Phone:</strong> {guest.phone}</p>
                            <div className="flex mt-2 space-x-4">
                              <button className="bg-blue-500 text-white px-3 py-1 rounded" onClick={() => handleEditUser(guest)}>Edit</button>
                              <button className="bg-red-500 text-white px-3 py-1 rounded" onClick={() => handleDeleteUser(guest.id)}>Delete</button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            <nav className="flex items-center bg-dark-purple flex-column flex-wrap md:flex-row justify-between" aria-label="Table navigation">
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                Showing <span className="font-semibold text-gray-900 dark:text-white">{(currentPage - 1) * itemsPerPage + 1}</span> to <span className="font-semibold text-gray-900 dark:text-white">{currentPage * itemsPerPage}</span> of <span className="font-semibold text-gray-900 dark:text-white">{data.length}</span>
              </span>
              <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8 p-2 mb-4">
                <li>
                  <button onClick={handlePrevious} disabled={currentPage === 1} className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 bg-dark-purple:bg-gray-800 bg-dark-purple:border-gray-700 bg-dark-purple:text-gray-400 bg-dark-purple:hover:bg-gray-700 bg-dark-purple:hover:text-white">
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, index) => (
                  <li key={index}>
                    <button onClick={() => handleClick(index + 1)} className={`flex items-center justify-center px-3 h-8 leading-tight ${currentPage === index + 1 ? 'text-blue-600 border border-gray-300 bg-blue-100' : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700'} bg-dark-purple:bg-gray-800 bg-dark-purple:border-gray-700 bg-dark-purple:text-gray-400 bg-dark-purple:hover:bg-gray-700 bg-dark-purple:hover:text-white`}>
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li>
                  <button onClick={handleNext} disabled={currentPage === totalPages} className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 bg-dark-purple:bg-gray-800 bg-dark-purple:border-gray-700 bg-dark-purple:text-gray-400 bg-dark-purple:hover:bg-gray-700 bg-dark-purple:hover:text-bold">
                    Next
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          {viewingUser && editMode && (
            <div className="my-4">
              <h2 className="text-lg font-bold">Edit User</h2>
              <div className="bg-white p-4 rounded shadow-md">
                <p><strong>First Name:</strong> <input type="text" value={editedUser.firstName} onChange={(e) => setEditedUser({ ...editedUser, firstName: e.target.value })} /></p>
                <p><strong>Last Name:</strong> <input type="text" value={editedUser.lastName} onChange={(e) => setEditedUser({ ...editedUser, lastName: e.target.value })} /></p>
                <p><strong>Gender:</strong> <input type="text" value={editedUser.gender} onChange={(e) => setEditedUser({ ...editedUser, gender: e.target.value })} /></p>
                <p><strong>Age:</strong> <input type="text" value={editedUser.age} onChange={(e) => setEditedUser({ ...editedUser, age: parseInt(e.target.value) })} /></p>
                <p><strong>City:</strong> <input type="text" value={editedUser.city} onChange={(e) => setEditedUser({ ...editedUser, city: e.target.value })} /></p>
                <p><strong>Region:</strong> <input type="text" value={editedUser.region} onChange={(e) => setEditedUser({ ...editedUser, region: e.target.value })} /></p>
                <p><strong>Phone:</strong> <input type="text" value={editedUser.phone} onChange={(e) => setEditedUser({ ...editedUser, phone: e.target.value })} /></p>
                <button type="button" onClick={handleSaveEdit} className="bg-green-500 text-white px-4 py-2 rounded">Save</button>
                <button type="button" onClick={handleCancelEdit} className="bg-gray-500 text-white px-4 py-2 rounded ml-2">Cancel</button>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
