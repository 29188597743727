import React, { useState, useMemo, useCallback } from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';
import { salesData } from './data';
import { format, parseISO, isWithinInterval } from 'date-fns';

const SalesTable = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filterMonth, setFilterMonth] = useState('');
  const [filterYear, setFilterYear] = useState('');
  const [storeClerk, setStoreClerk] = useState('');
  const [buyer, setBuyer] = useState('');
  const [expandedRows, setExpandedRows] = useState({});

  const data = useMemo(() => salesData.map(item => ({
    ...item,
    total: item.items.reduce((sum, current) => sum + (current.price * current.quantity), 0)
  })), []);

  const columns = useMemo(() => [
    { Header: 'Order Number', accessor: 'orderNumber' },
    { Header: 'Date', accessor: 'date', Cell: ({ value }) => format(parseISO(value), 'yyyy-MM-dd HH:mm') },
    { Header: 'Clerk', accessor: 'storeClerk' },
    { Header: 'Buyer', accessor: 'buyer' },
    { Header: 'Total', accessor: 'total', Cell: ({ value }) => `$${parseFloat(value).toFixed(2)}` },
  ], []);

  const filteredData = useMemo(() => {
    return data.filter(item => {
      const date = parseISO(item.date);
      const inDateRange = startDate && endDate ? isWithinInterval(date, { start: parseISO(startDate), end: parseISO(endDate) }) : true;
      const inMonth = filterMonth ? format(date, 'MM') === filterMonth : true;
      const inYear = filterYear ? format(date, 'yyyy') === filterYear : true;
      const inClerk = storeClerk ? item.storeClerk.toLowerCase().includes(storeClerk.toLowerCase()) : true;
      const inBuyer = buyer ? item.buyer.toLowerCase().includes(buyer.toLowerCase()) : true;
      return inDateRange && inMonth && inYear && inClerk && inBuyer;
    });
  }, [data, startDate, endDate, filterMonth, filterYear, storeClerk, buyer]);

  const totalSum = useMemo(() => {
    return filteredData.reduce((sum, item) => sum + item.total, 0);
  }, [filteredData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: filteredData,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useSortBy,
    usePagination
  );

  const handleRowClick = useCallback((rowIndex) => {
    setExpandedRows(prev => ({
      ...prev,
      [rowIndex]: !prev[rowIndex]
    }));
  }, []);

  return (
    <>
      <div>
        <label>
          Start Date:
          <input type="date" value={startDate} onChange={e => setStartDate(e.target.value)} />
        </label>
        <label>
          End Date:
          <input type="date" value={endDate} onChange={e => setEndDate(e.target.value)} />
        </label>
        <label>
          Month:
          <input type="text" value={filterMonth} placeholder="MM" onChange={e => setFilterMonth(e.target.value)} />
        </label>
        <label>
          Year:
          <input type="text" value={filterYear} placeholder="YYYY" onChange={e => setFilterYear(e.target.value)} />
        </label>
        <label>
          Store Clerk:
          <input type="text" value={storeClerk} onChange={e => setStoreClerk(e.target.value)} placeholder="Store Clerk" />
        </label>
        <label>
          Buyer:
          <input type="text" value={buyer} onChange={e => setBuyer(e.target.value)} placeholder="Buyer" />
        </label>
      </div>
      <div style={{ textAlign: 'right', marginRight: '20px' }}>
        <h2 className="font-extrabold text-gray-600 mb-3">Total: ${totalSum.toFixed(2)}</h2>
      </div>
      <table {...getTableProps()} style={{ width: '100%', borderCollapse: 'collapse' }} className="w-full text-sm text-left rtl:text-right">
        <thead className="text-xs text-white font-bold uppercase bg-dark-purple">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{ borderBottom: '1px solid black', padding: '10px' }}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-gray-100 text-base text-black font-serif shadow-md sm:rounded-lg border-dark-purple">
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <React.Fragment key={row.id}>
                <tr {...row.getRowProps()} onClick={() => handleRowClick(i)}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()} style={{ padding: '10px', borderBottom: '1px solid gray' }}>
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
                {expandedRows[i] && (
                  <tr>
                    <td colSpan={columns.length} style={{ padding: '10px', borderBottom: '1px solid gray', background: '#f9f9f9' }}>
                      <div>
                        <h3>Transaction Details</h3>
                        <ul>
                          {row.original.items.map((item, index) => (
                            <li key={index} style={{ marginBottom: '5px' }}>
                              <strong>{item.name}</strong> - Quantity: {item.quantity}, Price: ${item.price.toFixed(2)}, Total: ${(item.quantity * item.price).toFixed(2)}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <div>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>
        <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default SalesTable;
