import React from 'react';
import storeitems from './StoreItems.json';

const ShopPage = () => {
  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        {storeitems.items.map((item) => (
          <div key={item.id} className="flex flex-col bg-white shadow-lg rounded-lg overflow-hidden">
            <img className="w-full h-48 object-cover" src={item.image} alt={item.title} />
            <div className="p-4 flex flex-col justify-between flex-1">
              <div>
                <h2 className="text-xl font-bold mb-2">{item.title}</h2>
                <p className="text-gray-700 mb-4">${item.price.toFixed(2)}</p>
                <p className="text-gray-600 mb-4">{item.description}</p>
              </div>
              <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 mt-auto">
                Add to Cart
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ShopPage;
