import React from "react";
import Sidebar from "../global/Sidebar";
import DropdownMenu from "../global/DropdownMenu";
import NotificationMenu from "../global/NotificationMenu";

export default function Support() {
  document.title = "Support";
  return (
    <div className="flex h-screen bg-gray-100 overflow-hidden">
      <Sidebar />
      <main className="flex-1 flex  flex-col overflow-y-auto">
        <div className="flex justify-between items-center bg-top-bar p-3 md:p-9 shadow-md">
          <div className="flex items-center space-x-4 z-20">
            <NotificationMenu />
            <DropdownMenu />
          </div>
        </div>
        <div className="p-3 md:p-7 bg-gray-300 flex-1 z-10">
          
        </div>
      </main>
    </div>
  );
}