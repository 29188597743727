import { Routes, Route } from "react-router-dom";
import Dashboard from "./DashboardMenu/Dashboard";
import Guests from "./guest/Guests";
import Billing from "./billing/Billing";
import Sales from "./sales/Sales";
import Buildings from "./suites/Buildings";
import Building from "./suites/Building";
import UserProfile from "./profile/UserProfile";
import Support from "./support/Support";
import Settings from "./settings/Settings";
import Admin from "./admin/Admin";
import Login from "./auth/Login";
import Signup from "./auth/Signup";
import AddBuilding from "./suites/addbuilding";
import AddRoom from "./suites/AddRoom";
import ProtectedRoutes from "./auth/ProtectedRoutes";

function App() {
  return (  
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
              <Route element={<ProtectedRoutes/> }>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/addbuilding" element={<AddBuilding />} />
                  <Route path="/building/addroom" element={<AddRoom />} />
                  <Route path="/building/:buildingid" element={<Building />} />
                  <Route path="/guests" element={<Guests />} />
                  <Route path="/billing" element={<Billing />} />
                  <Route path="/sales" element={<Sales />} />
                  <Route path="/buildings" element={<Buildings />} />
                  <Route path="/support" element={<Support />} />
                  <Route path="/profile" element={<UserProfile />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/admin" element={<Admin />} />
              </Route>
            </Routes>
  );
}

export default App;