import axios from "axios";

class UserService{
  //  static BASE_URL = "http://localhost:8080"   
    static BASE_URL = "https://renter-vl8v.onrender.com"

    static async login(username, password){
        try{
            const response = await axios.post(`${UserService.BASE_URL}/auth/login`, {username, password})
            return response.data;

        }catch(err){
            throw err;
        }
    }

    static async register(userData){
        try{
            const response = await axios.post(`${UserService.BASE_URL}/auth/register`, userData)
            return response.data;
        }catch(err){
            throw err;
        }
    }

    
    static async getAllUsers(token){
        try{
            const response = await axios.get(`${UserService.BASE_URL}/admin/get-all-users`, 
            {
                headers: {Authorization: `Bearer ${token}`}
            })
            return response.data;
        }catch(err){
            throw err;
        }
    }

    /**BUILDING SERVICES */
    static async getOrgBuildings(token, orgId, page = 0, size = 10) {
        try {
            const response = await axios.get(`${this.BASE_URL}/api/v1/orgbuildings`, {
                params: {
                    orgid: orgId,
                    page: page,
                    size: size
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    static async addOrgBuilding(token, organizationid, postData) {
        try {
            const response = await axios.post(`${this.BASE_URL}/api/v1/building`, postData, {
                params: {
                    organizationid: organizationid
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    static async getBuilding(token, buildingid, page = 0, size = 10) {
        try {
            const response = await axios.get(`${this.BASE_URL}/api/v1/building/details`, {
                params: {
                    buildingid: buildingid,
                    page: page,
                    size: size
                },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (err) {
            throw err;
        }
    }

    /**AUTHENTICATION CHECKER */
    static logout(){
        localStorage.removeItem('token')
        localStorage.removeItem('role')
    }

    static isAuthenticated(){
        const token = localStorage.getItem('token')
        return !!token
    }

    static isAdmin(){
        const role = localStorage.getItem('role')
        return role === 'ADMIN'
    }

    static isUser(){
        const role = localStorage.getItem('role')
        return role === 'USER'
    }

    static adminOnly(){
        return this.isAuthenticated() && this.isAdmin();
    }

}

export default UserService;