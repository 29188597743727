import React, {useState} from 'react';
import { FaEnvelope, FaLock } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom'; // Import Link for routing
import Signup from './Signup'; // Import the Signup component
import AuthService from "./AuthService";

const backgroundStyle = {
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};

export default function Login() {
  document.title = "Login";
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const userData = await AuthService.login(username, password)
        console.log(userData)
        if (userData.token) {
            localStorage.setItem('token', userData.token)
            localStorage.setItem('role', userData.role)
            localStorage.setItem('org', userData.org)
            navigate('/dashboard')
        }else{
            setError(userData.message)
        }
        
    } catch (error) {
        console.log(error)
        setError(error.message)
        setTimeout(()=>{
            setError('');
        }, 5000);
    }
}

  // State for toggling between login and signup views
  const [showSignup, setShowSignup] = React.useState(false);

  return (
    <div style={backgroundStyle} className="flex flex-col items-center justify-center min-h-screen py-2">
      <main className="flex flex-col items-center justify-center w-full flex-1 px-4 text-center sm:px-20">
        <div className="bg-white rounded-2xl shadow-2xl flex flex-col w-full max-w-xl sm:flex-row">
          <div className="w-full p-5">
            <div className="text-left font-bold">
              <span className="text-dark-purple">Company</span>
            </div>
            <form onSubmit={handleSubmit}>
            <div className="py-10">
              <h2 className="text-3xl font-bold text-dark-purple mb-1">Good to see you again.</h2>
              {error && <p className="error-message">{error}</p>}
              <div className="border-2 w-10 border-dark-purple inline-block mb-2"></div>
            </div>
            <div className="flex flex-col items-center mb-3">
              <div className="bg-gray-100 w-full max-w-xs p-2 flex items-center">
                <FaEnvelope className="text-dark-purple m-2" />
                <input type="email" value={username}  onChange={(e) => setUsername(e.target.value)} placeholder="Email" className="bg-gray-100 outline-none text-sm flex-1" />
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="bg-gray-100 w-full max-w-xs p-2 flex items-center mb-3">
                <FaLock className="text-dark-purple m-2" />
                <input type="password"  value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" className="bg-gray-100 outline-none text-sm flex-1" />
              </div>
              <div className="flex justify-between w-full max-w-xs mb-5">
                <label className="flex items-center text-xs"><input type="checkbox" name="remember" className="mr-1" />Remember me</label>
                <a href="#" className="text-xs">Forgot Password</a>
              </div>
              <div className="flex">
                <button type="submit" className="border-2 border-dark-purple text-dark-purple rounded-full px-10 py-2 inline-block font-semibold hover:bg-dark-purple hover:text-white">Sign In</button>
              </div>
              <div className="mt-5">
                {/* Use Link for Sign Up button */}
                <Link to="/signup" className="text-sm text-dark-purple hover:underline">Don't have an account? Sign Up</Link>
              </div>
            </div>
            </form>
          </div>
          
          {showSignup && <Signup />} {/* Conditional rendering of Signup component */}
        </div>
      </main>
    </div>
  );
}
