import { useState } from "react";
import { AiOutlineBell } from "react-icons/ai";

const NotificationMenu = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="fixed top-4 right-16">
      <button
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="bg-dark-purple text-white p-2 rounded-full focus:outline-none"
      >
        <AiOutlineBell className="text-2xl" />
      </button>
      {dropdownOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg">
          <ul>
            <li className="border-b border-gray-200">
              <a
                href="/notification1"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-dark-purple hover:text-white"
              >
                Notification 1
              </a>
            </li>
            <li className="border-b border-gray-200">
              <a
                href="/notification2"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-dark-purple hover:text-white"
              >
                Notification 2
              </a>
            </li>
            <li>
              <a
                href="/notification3"
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-dark-purple hover:text-white"
              >
                Notification 3
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default NotificationMenu;
