export const salesData = [
  {
    orderNumber: 1,
    date: '2023-07-14T10:30:00Z',
    storeClerk: 'John Doe',
    buyer: 'Alice Johnson',
    items: [
      { name: 'Coca Cola', quantity: 2, price: 1.5 },
      { name: 'Pepsi', quantity: 1, price: 1.5 },
      { name: 'Sprite', quantity: 1, price: 1.25 },
    ]
  },
  {
    orderNumber: 2,
    date: '2023-07-15T11:00:00Z',
    storeClerk: 'Jane Smith',
    buyer: 'Bob Brown',
    items: [
      { name: 'Whiskey', quantity: 1, price: 25.0 },
      { name: 'Vodka', quantity: 2, price: 20.0 },
      { name: 'Rum', quantity: 1, price: 15.0 },
      { name: 'Gin', quantity: 1, price: 18.0 },
    ]
  },
  {
    orderNumber: 3,
    date: '2023-07-16T12:00:00Z',
    storeClerk: 'Jack Johnson',
    buyer: 'Charlie Green',
    items: [
      { name: 'Coca Cola', quantity: 3, price: 1.5 },
      { name: 'Fanta', quantity: 2, price: 1.25 },
      { name: 'Mountain Dew', quantity: 2, price: 1.5 },
      { name: 'Pepsi', quantity: 1, price: 1.5 },
      { name: 'Sprite', quantity: 1, price: 1.25 },
    ]
  },
  {
    orderNumber: 4,
    date: '2023-07-17T13:00:00Z',
    storeClerk: 'Emily Davis',
    buyer: 'David White',
    items: [
      { name: 'Beer', quantity: 6, price: 2.5 },
      { name: 'Wine', quantity: 2, price: 15.0 },
      { name: 'Whiskey', quantity: 1, price: 25.0 },
      { name: 'Vodka', quantity: 3, price: 20.0 },
      { name: 'Gin', quantity: 1, price: 18.0 },
      { name: 'Rum', quantity: 1, price: 15.0 },
    ]
  },
  {
    orderNumber: 5,
    date: '2023-07-18T14:00:00Z',
    storeClerk: 'Michael Brown',
    buyer: 'Eve Black',
    items: [
      { name: 'Coca Cola', quantity: 2, price: 1.5 },
      { name: 'Sprite', quantity: 1, price: 1.25 },
      { name: 'Fanta', quantity: 3, price: 1.25 },
      { name: 'Pepsi', quantity: 1, price: 1.5 },
      { name: 'Mountain Dew', quantity: 2, price: 1.5 },
    ]
  },
  {
    orderNumber: 6,
    date: '2023-07-19T15:00:00Z',
    storeClerk: 'Chris Wilson',
    buyer: 'Frank Red',
    items: [
      { name: 'Whiskey', quantity: 1, price: 25.0 },
      { name: 'Vodka', quantity: 2, price: 20.0 },
      { name: 'Gin', quantity: 1, price: 18.0 },
    ]
  },
  {
    orderNumber: 7,
    date: '2023-07-20T16:00:00Z',
    storeClerk: 'Sarah Taylor',
    buyer: 'Grace Blue',
    items: [
      { name: 'Coca Cola', quantity: 3, price: 1.5 },
      { name: 'Pepsi', quantity: 1, price: 1.5 },
      { name: 'Sprite', quantity: 2, price: 1.25 },
      { name: 'Mountain Dew', quantity: 2, price: 1.5 },
      { name: 'Fanta', quantity: 1, price: 1.25 },
      { name: 'Dr. Pepper', quantity: 2, price: 1.5 },
    ]
  },
  {
    orderNumber: 8,
    date: '2023-07-21T17:00:00Z',
    storeClerk: 'Laura Moore',
    buyer: 'Henry Grey',
    items: [
      { name: 'Beer', quantity: 6, price: 2.5 },
      { name: 'Wine', quantity: 2, price: 15.0 },
      { name: 'Whiskey', quantity: 1, price: 25.0 },
      { name: 'Vodka', quantity: 3, price: 20.0 },
      { name: 'Gin', quantity: 1, price: 18.0 },
      { name: 'Rum', quantity: 1, price: 15.0 },
      { name: 'Tequila', quantity: 2, price: 30.0 },
    ]
  },
  {
    orderNumber: 9,
    date: '2023-07-22T18:00:00Z',
    storeClerk: 'Robert Anderson',
    buyer: 'Ivy Brown',
    items: [
      { name: 'Coca Cola', quantity: 4, price: 1.5 },
      { name: 'Pepsi', quantity: 2, price: 1.5 },
      { name: 'Sprite', quantity: 2, price: 1.25 },
      { name: 'Fanta', quantity: 3, price: 1.25 },
      { name: 'Mountain Dew', quantity: 1, price: 1.5 },
    ]
  },
  {
    orderNumber: 10,
    date: '2023-07-23T19:00:00Z',
    storeClerk: 'David Martinez',
    buyer: 'Jack Purple',
    items: [
      { name: 'Whiskey', quantity: 2, price: 25.0 },
      { name: 'Vodka', quantity: 2, price: 20.0 },
      { name: 'Rum', quantity: 1, price: 15.0 },
      { name: 'Gin', quantity: 1, price: 18.0 },
    ]
  },
  {
    orderNumber: 11,
    date: '2023-07-24T20:00:00Z',
    storeClerk: 'Emma Thompson',
    buyer: 'Kelly Pink',
    items: [
      { name: 'Coca Cola', quantity: 3, price: 1.5 },
      { name: 'Pepsi', quantity: 2, price: 1.5 },
      { name: 'Sprite', quantity: 2, price: 1.25 },
      { name: 'Fanta', quantity: 1, price: 1.25 },
      { name: 'Mountain Dew', quantity: 2, price: 1.5 },
    ]
  },
  {
    orderNumber: 12,
    date: '2023-07-25T21:00:00Z',
    storeClerk: 'Olivia White',
    buyer: 'Liam Green',
    items: [
      { name: 'Beer', quantity: 4, price: 2.5 },
      { name: 'Wine', quantity: 2, price: 15.0 },
      { name: 'Whiskey', quantity: 1, price: 25.0 },
      { name: 'Vodka', quantity: 2, price: 20.0 },
      { name: 'Gin', quantity: 1, price: 18.0 },
      { name: 'Rum', quantity: 1, price: 15.0 },
    ]
  },
  {
    orderNumber: 13,
    date: '2023-07-26T22:00:00Z',
    storeClerk: 'Ava Clark',
    buyer: 'Mason White',
    items: [
      { name: 'Coca Cola', quantity: 2, price: 1.5 },
      { name: 'Pepsi', quantity: 3, price: 1.5 },
      { name: 'Sprite', quantity: 2, price: 1.25 },
      { name: 'Fanta', quantity: 1, price: 1.25 },
    ]
  },
  {
    orderNumber: 14,
    date: '2023-07-27T23:00:00Z',
    storeClerk: 'Isabella Rodriguez',
    buyer: 'Noah Black',
    items: [
      { name: 'Whiskey', quantity: 1, price: 25.0 },
      { name: 'Vodka', quantity: 2, price: 20.0 },
      { name: 'Gin', quantity: 1, price: 18.0 },
      { name: 'Rum', quantity: 2, price: 15.0 },
      { name: 'Tequila', quantity: 1, price: 30.0 },
    ]
  },
  {
    orderNumber: 15,
    date: '2023-07-28T09:00:00Z',
    storeClerk: 'Sophia Martinez',
    buyer: 'Oliver Grey',
    items: [
      { name: 'Coca Cola', quantity: 3, price: 1.5 },
      { name: 'Pepsi', quantity: 2, price: 1.5 },
      { name: 'Sprite', quantity: 3, price: 1.25 },
      { name: 'Mountain Dew', quantity: 2, price: 1.5 },
    ]
  },
  {
    orderNumber: 16,
    date: '2023-07-29T10:00:00Z',
    storeClerk: 'Mia Walker',
    buyer: 'William Green',
    items: [
      { name: 'Beer', quantity: 6, price: 2.5 },
      { name: 'Wine', quantity: 1, price: 15.0 },
      { name: 'Whiskey', quantity: 1, price: 25.0 },
      { name: 'Vodka', quantity: 2, price: 20.0 },
    ]
  },
  {
    orderNumber: 17,
    date: '2023-07-30T11:00:00Z',
    storeClerk: 'Charlotte Harris',
    buyer: 'James Red',
    items: [
      { name: 'Coca Cola', quantity: 2, price: 1.5 },
      { name: 'Pepsi', quantity: 2, price: 1.5 },
      { name: 'Sprite', quantity: 2, price: 1.25 },
      { name: 'Mountain Dew', quantity: 2, price: 1.5 },
      { name: 'Fanta', quantity: 1, price: 1.25 },
      { name: 'Dr. Pepper', quantity: 1, price: 1.5 },
      { name: 'Ginger Ale', quantity: 1, price: 1.5 },
    ]
  },
  {
    orderNumber: 18,
    date: '2023-07-31T12:00:00Z',
    storeClerk: 'Amelia Hall',
    buyer: 'Alexander Blue',
    items: [
      { name: 'Whiskey', quantity: 2, price: 25.0 },
      { name: 'Vodka', quantity: 2, price: 20.0 },
      { name: 'Rum', quantity: 2, price: 15.0 },
      { name: 'Gin', quantity: 1, price: 18.0 },
    ]
  },
  {
    orderNumber: 19,
    date: '2023-08-01T13:00:00Z',
    storeClerk: 'Evelyn Young',
    buyer: 'Benjamin Pink',
    items: [
      { name: 'Coca Cola', quantity: 3, price: 1.5 },
      { name: 'Pepsi', quantity: 2, price: 1.5 },
      { name: 'Sprite', quantity: 1, price: 1.25 },
      { name: 'Mountain Dew', quantity: 1, price: 1.5 },
      { name: 'Fanta', quantity: 1, price: 1.25 },
    ]
  },
  {
    orderNumber: 20,
    date: '2023-08-02T14:00:00Z',
    storeClerk: 'Harper King',
    buyer: 'Ella White',
    items: [
      { name: 'Beer', quantity: 6, price: 2.5 },
      { name: 'Wine', quantity: 1, price: 15.0 },
      { name: 'Whiskey', quantity: 1, price: 25.0 },
      { name: 'Vodka', quantity: 2, price: 20.0 },
      { name: 'Gin', quantity: 1, price: 18.0 },
      { name: 'Rum', quantity: 1, price: 15.0 },
    ]
  }
];
