import React, { useState } from "react";
import Sidebar from "../global/Sidebar";
import DropdownMenu from "../global/DropdownMenu";
import NotificationMenu from "../global/NotificationMenu";

const UserProfile = () => {
  const [profile, setProfile] = useState({
    firstName: "John",
    lastName: "Doe",
    email: "john.doe@example.com",
    phone: "123-456-7890",
    address: "123 Main St",
    city: "Anytown",
    state: "CA",
    country: "USA",
    gender: "Male",
    dateOfBirth: "1990-01-01",
    password: "",
  });

  const [editMode, setEditMode] = useState(false);
  const [form, setForm] = useState({
    email: profile.email,
    phone: profile.phone,
    address: profile.address,
    city: profile.city,
    state: profile.state,
    password: "",
    repeatPassword: "",
  });
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSave = () => {
    // Check if passwords match
    if (form.password !== form.repeatPassword) {
      setPasswordsMatch(false);
      return;
    }

    // Update profile
    setProfile({
      ...profile,
      email: form.email,
      phone: form.phone,
      address: form.address,
      city: form.city,
      state: form.state,
      password: form.password,
    });
    setEditMode(false);
    setPasswordsMatch(true);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  return (
    <div className="flex h-screen bg-gray-100 overflow-hidden">
      <Sidebar />
      <main className="flex-1 flex  flex-col overflow-y-auto">
        <div className="flex justify-between items-center bg-top-bar p-3 md:p-9 shadow-md">
          <div className="flex items-center space-x-4 z-20">
            <NotificationMenu />
            <DropdownMenu />
          </div>
        </div>
        <div className="p-3 md:p-7 bg-gray-300 flex-1 z-10">
          <div className="container mx-auto p-4">
            <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 p-4">
                <div className="col-span-1">
                  <img
                    className="w-full h-auto object-cover rounded-md"
                    src="https://via.placeholder.com/500x300"
                    alt="Profile"
                  />
                </div>
                <div className="col-span-1">
                  <div className="flex flex-col h-full">
                    <div className="p-4">
                      <h2 className="text-2xl font-bold mb-2">
                        {profile.firstName} {profile.lastName}
                      </h2>
                      <div className="mb-4">
                        <label className="text-gray-600">Email:</label>
                        {editMode ? (
                          <input
                            type="email"
                            name="email"
                            value={form.email}
                            onChange={handleChange}
                            className="form-input block w-full mt-1"
                          />
                        ) : (
                          <span className="text-gray-800">{profile.email}</span>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="text-gray-600">Phone:</label>
                        {editMode ? (
                          <input
                            type="text"
                            name="phone"
                            value={form.phone}
                            onChange={handleChange}
                            className="form-input block w-full mt-1"
                          />
                        ) : (
                          <span className="text-gray-800">{profile.phone}</span>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="text-gray-600">Address:</label>
                        {editMode ? (
                          <input
                            type="text"
                            name="address"
                            value={form.address}
                            onChange={handleChange}
                            className="form-input block w-full mt-1"
                          />
                        ) : (
                          <span className="text-gray-800">
                            {profile.address}
                          </span>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="text-gray-600">City:</label>
                        {editMode ? (
                          <input
                            type="text"
                            name="city"
                            value={form.city}
                            onChange={handleChange}
                            className="form-input block w-full mt-1"
                          />
                        ) : (
                          <span className="text-gray-800">{profile.city}</span>
                        )}
                      </div>
                      <div className="mb-4">
                        <label className="text-gray-600">State:</label>
                        {editMode ? (
                          <input
                            type="text"
                            name="state"
                            value={form.state}
                            onChange={handleChange}
                            className="form-input block w-full mt-1"
                          />
                        ) : (
                          <span className="text-gray-800">{profile.state}</span>
                        )}
                      </div>
                      {editMode && (
                        <div className="mb-4">
                          <label className="text-gray-600">Password:</label>
                          <input
                            type="password"
                            name="password"
                            value={form.password}
                            onChange={handleChange}
                            className="form-input block w-full mt-1"
                          />
                        </div>
                      )}
                      {editMode && (
                        <div className="mb-4">
                          <label className="text-gray-600">
                            Repeat Password:
                          </label>
                          <input
                            type="password"
                            name="repeatPassword"
                            value={form.repeatPassword}
                            onChange={handleChange}
                            className={`form-input block w-full mt-1 ${
                              !passwordsMatch ? "border-red-500" : ""
                            }`}
                          />
                          {!passwordsMatch && (
                            <p className="text-red-500 text-sm mt-1">
                              Passwords do not match.
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="p-4 mt-auto">
                      <div className="flex justify-end">
                        {editMode ? (
                          <button
                            onClick={handleSave}
                            className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
                          >
                            Save
                          </button>
                        ) : (
                          <button
                            onClick={handleEdit}
                            className="bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400"
                          >
                            Edit Profile
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UserProfile;
