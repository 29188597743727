import { useState } from "react";
import { NavLink } from "react-router-dom";
import { BsArrowLeftShort, BsSearch } from "react-icons/bs";
import AuthService from "../auth/AuthService";

import {
  AiFillEnvironment,
  AiOutlineCreditCard,
  AiOutlineSetting,
  AiOutlineUser,
  AiOutlineHome,
  AiOutlineDashboard,
} from "react-icons/ai";
import { RiDashboardFill } from "react-icons/ri";
import { BiSupport } from "react-icons/bi";
import { TbReport } from "react-icons/tb";
import { GrUserAdmin } from "react-icons/gr";

const Sidebar = () => {
  const activeLink = "bg-gray-100 duration-200 text-gray-500 hover:bg-white";
  const normalLink = "";
  const [open, setOpen] = useState(true);
  const Menus = [
    { title: "Dashboard", auth:"isAuthenticated", link: "/dashboard", icon: <AiOutlineDashboard /> },
    { title: "Listings", auth:"isAuthenticated", link: "/buildings", icon: <AiOutlineHome /> },
    // { title: "Guests", auth:"isAuthenticated", link: "/guests", icon: <AiOutlineUsergroupAdd /> },
    { title: "Sales", auth:"isAuthenticated", link: "/sales", icon: <TbReport /> },
    { title: "Billing", auth:"isAuthenticated", link: "/billing", icon: <AiOutlineCreditCard /> },
    { title: "Admin", auth:"isAuthenticated", link: "/admin", icon: <GrUserAdmin /> },
    { title: "Setting", auth:"isAuthenticated", link: "/settings", icon: <AiOutlineSetting /> },
    { title: "Profile", auth:"isAuthenticated", link: "/profile", icon: <AiOutlineUser /> },
    { title: "Support", auth:"isAuthenticated", link: "/support", icon: <BiSupport /> },
  ];
  const isAuthenticated = AuthService.isAuthenticated();
  const isAdmin = AuthService.isAdmin();

const filteredMenus = Menus.filter(menu => {
  if (menu.auth === "isAuthenticated" && isAuthenticated) return true;
  if (menu.auth === "isAdmin" && isAdmin) return true;
  return false;
});


  return (
    <div className="flex">
      <div
        className={`bg-dark-purple h-screen p-5 pt-8 ${
          open ? "w-72" : "w-20"
        } duration-300 relative`}
      >
        <BsArrowLeftShort
          className={`bg-white text-dark-purple text-3xl rounded-full absolute -right-3 top-9 border border-dark-purple cursor-pointer ${
            !open && "rotate-180"
          }`}
          onClick={() => setOpen(!open)}
        />
        <div className="inline-flex items-center">
          <AiFillEnvironment
            className={`bg-amber-300 text-4xl rounded cursor-pointer block float-left mr-2 duration-500 ${
              open && "rotate-[360deg]"
            }`}
          />
          <h1
            className={`text-white origin-left font-medium text-2xl duration-300 ${
              !open && "scale-0"
            }`}
          >
            Tailwind
          </h1>
        </div>
        <div
          className={`flex items-center rounded-md bg-light-white mt-6 ${
            !open ? "px-4" : "px-2.5"
          } py-2`}
        >
          <BsSearch
            className={`text-white text-lg block float-left cursor-pointer ${
              open && "mr-2"
            }`}
          />
          <input
            type={"search"}
            placeholder="Search"
            className={`text-base bg-transparent w-full text-white focus:outline-none ${
              !open && "hidden"
            }`}
          />
        </div>
        <ul className="pt-2">
          {filteredMenus.map((menu, index) => (
            <NavLink
              to={menu.link}
              key={index}
              className={({ isActive }) =>
                `text-gray-300 text-sm flex items-center gap-x-4 cursor-pointer p-2 hover:bg-light-white rounded-md ${
                  menu.spacing ? "mt-9" : "mt-2"
                } ${isActive ? activeLink : normalLink}`
              }
            >
              <span className="text-2xl block float-left">
                {menu.icon ? menu.icon : <RiDashboardFill />}
              </span>
              <span
                className={`text-base font-bold flex-1 duration-200 ${
                  !open ? "hidden" : ""
                }`}
              >
                {menu.title}
              </span>
            </NavLink>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
