import React from 'react'

export default function DashboardTable() {
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mb-4">
  <table className="w-full text-sm text-left rtl:text-right ">
    <thead className="text-xs text-white font-bold uppercase bg-dark-purple">
      <tr>
        <th scope="col" colSpan="7" className="text-center px-6 py-3">
          Booking Summary
        </th>
      </tr>
    </thead>
    <tbody className="bg-gray-100 text-base text-black font-serif shadow-md sm:rounded-lg border-dark-purple">
      <tr>
        <td className="px-6 py-1">
          ID: 0923                                                                         
        </td>
        <td className="px-6 py-1">
          First Name: Nathan                                                                         
        </td>
        <td className="px-6 py-1 hidden md:table-cell">
          Last Name: Cole                                   
        </td>
        <td className="px-6 py-1 hidden md:table-cell">
          Check in: 12/23/2021                                    
        </td>
        <td className="px-6 py-1">
          Check out: 12/23/2021                                         
        </td>
        <td className="px-6 py-1">
          Suite: 4                                       
        </td>
        <td className="px-6 py-1">
          Bill: 4000 FCFA                                      
        </td>
      </tr>
    </tbody>
  </table>                  
</div>

  )
}
