import React, { useState } from "react";
import Sidebar from "../global/Sidebar";
import DropdownMenu from "../global/DropdownMenu";
import NotificationMenu from "../global/NotificationMenu";
import AuthService from "../auth/AuthService";

export default function AddRoom() {
  document.title = "AddRoom";
  const [formData, setFormData] = useState({
    currency: "",
    buildingName: "",
    imageUrls: [],
    address: "",
    country: "",
    state: "",
    city: "",
    phone: "",
  });

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle file uploads for images (limit to 4 files)
  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);

    if (files.length + formData.imageUrls.length > 10) {
      setErrorMessage("You can only upload up to 10 images.");
    } else {
      setErrorMessage("");
      const newImageUrls = [...formData.imageUrls, ...files];
      setFormData((prevData) => ({
        ...prevData,
        imageUrls: newImageUrls,
      }));
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const postData = new FormData(); 
    postData.append("buildingName", formData.buildingName);
    postData.append("address", formData.address);
    postData.append("country", formData.country);
    postData.append("state", formData.state);
    postData.append("city", formData.city);
    postData.append("phone", formData.phone);
    postData.append("currency", formData.currency);
    formData.imageUrls.forEach((file, index) => {
        postData.append(`images`, file); 
    });


    try {
      const token = localStorage.getItem('token'); 
      const org = localStorage.getItem('org'); 
      const response = await AuthService.addOrgBuilding(token,org, postData);
      setSuccessMessage("Building added successfully!");
      console.log(response.data);
    } catch (error) {
      setErrorMessage("There was an error submitting the form.");
      console.error(error);
    }
  };

  // Remove an uploaded image
  const removeImage = (index) => {
    const newImageUrls = formData.imageUrls.filter((_, i) => i !== index);
    setFormData((prevData) => ({
      ...prevData,
      imageUrls: newImageUrls,
    }));
  };

  return (
    <div className="flex h-screen bg-gray-100 overflow-hidden">
      <Sidebar />
      <main className="flex-1 flex  flex-col overflow-y-auto">
        <div className="flex justify-between items-center bg-top-bar p-3 md:p-9 shadow-md">
          <div className="flex items-center space-x-4 z-20">
            <NotificationMenu />
            <DropdownMenu />
          </div>
        </div>
        <div className="p-3 md:p-7 bg-gray-300 flex-1 z-10">
          <form onSubmit={handleSubmit} className="max-w-xl mx-auto p-6 bg-white rounded-lg shadow-lg space-y-4">
            <h2 className="text-2xl font-bold mb-6 text-gray-700">Room Information</h2>

            {/* Room Name */}
            <label className="block">
              <span className="text-gray-700">Room Name</span>
              <input
                type="text"
                name="buildingName"
                value={formData.buildingName}
                onChange={handleChange}
                className="mt-1 block w-full bg-gray-100 border border-gray-300 rounded-md py-2 px-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter building name"
              />
            </label>

            {/* Country */}
            <label className="block">
              <span className="text-gray-700">Country</span>
              <select
                name="country"
                value={formData.country}
                onChange={handleChange}
                className="mt-1 block w-full bg-gray-100 border border-gray-300 rounded-md py-2 px-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="">Select Country</option>
                <option value="CM">Cameroon</option>
                <option value="US">United States of America</option>
              </select>
            </label>

            {/* State */}
            <label className="block">
              <span className="text-gray-700">State</span>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
                className="mt-1 block w-full bg-gray-100 border border-gray-300 rounded-md py-2 px-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter state"
              />
            </label>

            {/* City */}
            <label className="block">
              <span className="text-gray-700">City</span>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleChange}
                className="mt-1 block w-full bg-gray-100 border border-gray-300 rounded-md py-2 px-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter city"
              />
            </label>

            {/* Address */}
            <label className="block">
              <span className="text-gray-700">Address</span>
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
                className="mt-1 block w-full bg-gray-100 border border-gray-300 rounded-md py-2 px-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter address"
              />
            </label>

            {/* Phone */}
            <label className="block">
              <span className="text-gray-700">Phone</span>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="mt-1 block w-full bg-gray-100 border border-gray-300 rounded-md py-2 px-3 text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Enter phone number"
              />
            </label>

            {/* Image Upload (Limit to 4 files) */}
            <label className="block">
              <span className="text-gray-700">Room Images (Max 10)</span>
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={handleImageUpload}
                className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-500 file:text-white hover:file:bg-blue-600"
              />
            </label>

            {/* Display uploaded images */}
            {formData.imageUrls.length > 0 && (
              <div className="mt-2 space-y-2">
                {formData.imageUrls.map((file, index) => (
                  <div key={index} className="flex items-center justify-between">
                    <span className="text-gray-600 text-sm">{file.name}</span>
                    <button
                      type="button"
                      onClick={() => removeImage(index)}
                      className="text-red-500 hover:text-red-700 text-xs"
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            )}

            {/* Error message */}
            {errorMessage && <p className="text-red-500 text-sm mt-2">{errorMessage}</p>}
            {successMessage && <p className="text-green-500 text-sm mt-2">{successMessage}</p>}

            {/* Submit Button */}
            <button
              type="submit"
              className="mt-4 w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Submit
            </button>
          </form>
        </div>
      </main>
    </div>
  );
}
